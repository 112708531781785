var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container-fluid wrapper" }, [
      _c("div", { attrs: { id: "title" } }, [_vm._v("友達リクエスト一覧")]),
      _c(
        "div",
        { staticClass: "spinner text-center" },
        [
          _vm.isLoading
            ? _c("b-spinner", {
                staticClass: "mt-5 mb-5",
                attrs: { variant: "primary", label: "Loading..." }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { attrs: { id: "main-content" } }, [
        _c(
          "div",
          { staticClass: "div text-center", attrs: { id: "content-header" } },
          [_vm._v(_vm._s(_vm.message))]
        ),
        _vm.friendRequestList.length == 0
          ? _c(
              "div",
              { staticClass: "div text-center" },
              [
                _c("router-link", { attrs: { to: "/" } }, [
                  _c("small", [_vm._v("TOPページに戻る")])
                ])
              ],
              1
            )
          : _c(
              "div",
              { attrs: { id: "table" } },
              _vm._l(_vm.friendRequestList, function(item, index) {
                return _c(
                  "div",
                  { staticClass: "table-row", attrs: { value: item } },
                  [
                    _c("div", { staticClass: "date" }, [
                      _vm._v(_vm._s(item.date))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "name",
                        on: {
                          click: function($event) {
                            return _vm.callRouterInfo(item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.userName))]
                    ),
                    _c("div", { staticClass: "approval" }, [
                      _c(
                        "div",
                        {
                          staticClass: "approval-button",
                          on: {
                            click: function($event) {
                              return _vm.sendApproval(item)
                            }
                          }
                        },
                        [_vm._v("追加")]
                      )
                    ]),
                    _c("div", { staticClass: "rejection" }, [
                      _c(
                        "div",
                        {
                          staticClass: "rejection-button",
                          on: {
                            click: function($event) {
                              return _vm.sendRejection(item)
                            }
                          }
                        },
                        [_vm._v("拒否")]
                      )
                    ])
                  ]
                )
              }),
              0
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }