<template lang="pug">
  div
    .container-fluid.wrapper
      #title 友達リクエスト一覧
      .spinner.text-center
        b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
      #main-content
        .div.text-center#content-header {{message}}
        .div.text-center(v-if="friendRequestList.length ==0")
          router-link(to="/") <small>TOPページに戻る</small>
        #table(v-else)
          .table-row(v-for="(item,index) in friendRequestList" :value="item")
            .date {{item.date}}
            .name(@click="callRouterInfo(item)") {{item.userName}}
            .approval 
              .approval-button(@click="sendApproval(item)") 追加
            .rejection
              .rejection-button(@click="sendRejection(item)") 拒否
                
</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  props:{
  },
  data() {
    return {
      friendRequestList:[],
      targetUserInfo:{},
      message:'',
      isLoading:true
    }
  },
  created(){
    this.getData()
  },
  // メソッド
  methods: {
    window:onload = function() {
    },
    async getData() {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info'
      await axios
        .get(jsonUrl,{
          params: {
            'friendRequestFlg' : 'true'
          },     
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          let wkReceiveFriendRequest = []
          wkReceiveFriendRequest = response.data[0]
          this.friendRequestList = wkReceiveFriendRequest.receiveFriendRequest
          if(this.friendRequestList.length == 0){
            //データ0件の場合
            this.message = '未承認の友達リクエストは存在しません。'
          }else{
            this.message = '友達リクエストが届いています。'
          }
        })
        .catch(err => {
          this.message ='情報の取得に失敗しました'
        })

        this.isLoading = false
    },
    //------------------------
    // 承認実行
    //------------------------
    async sendApproval(item){
      const message = item.userName + 'さん を友達に追加しますか'
      const answer1 = '追加する'
      const answer2 = '追加しない'
      if(!await this.asyncDialogAnswer(message,answer1,answer2,'success')){
        return
      }
      //承認APIを実行する
      await this.callSendApprovalApi(item.userId,false)
      //完了ダイアログ
      await this.asyncDialogComp('友達追加が完了しました。')
      //API実行後の共通処理
      await this.afterApiProcess(item.userId)

    },
    //------------------------
    // 承認(拒否)実行
    //------------------------
    async sendRejection(item){
      // 確認ダイアログ
      const message = item.userName + 'さん の友達リクエストを拒否しますか？'
      const answer1 = '拒否する'
      const answer2 = 'キャンセル'
      if(!await this.asyncDialogAnswer(message,answer1,answer2,'danger')){
        return
      }
      //承認APIを実行する
      await this.callSendApprovalApi(item.userId,true)
      //完了ダイアログ
      await this.asyncDialogComp('友達リクエストの拒否が完了しました。')
      //API実行後の共通処理
      await this.afterApiProcess(item.userId)
    },
    //--------------------------------
    // 承認、または拒否実行後の共通処理
    //--------------------------------
    async afterApiProcess(userId){

      //画面に表示するリストを更新する(今回承認or拒否の対象になったユーザーを除外する)
      this.friendRequestList = this.friendRequestList.filter(user => user.userId != userId)

      //承認前の友達リクエストが0件になった場合はトップ画面に遷移する
      if(this.friendRequestList.length == 0){
        await this.dataDoesNotExistProcess()
      }
    },
    async dataDoesNotExistProcess(){
      await this.asyncDialogComp('友達リクエストはありません。')
      this.$router.push('/') 
    },
    callRouterInfo(item){
      this.$router.push('/myPage/' + item.userId +'/userInfoTop')

    },
    //------------------------
    // 承認API実行
    //------------------------
    async callSendApprovalApi(targetUserId,isRefusal){
      //未ログイン時は何もしない
      if(!this.$user.isLogin){
        return
      }
      //API実行
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-friend-request/answer-request'
      const params = {
        targetUserId : targetUserId,
        isRefusal : isRefusal
      }
      axios.patch(jsonUrl, params, {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        //alert('API完了')
      })
      .catch(err => {
        alert('友達リクエストの承認処理でエラーが発生しました。')
      })

    },
    //------------------------
    //確認ダイアログ
    //------------------------
    asyncDialogAnswer(message,answer1,answer2,okVariant) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxConfirm(message, {
          title: '確認',
          okVariant: okVariant,
          okTitle: answer1,
          cancelTitle: answer2,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
    //------------------------
    //実行結果ダイアログ
    //------------------------
    asyncDialogComp(message) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(message, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
  },
  // コンポーネント
  components: {
  }
}
</script>
<style lang="stylus" scoped>

.wrapper
  padding 0px
  #title
    padding 1.5rem
    text-align center
    font-size 1.5rem
    font-weight 600  
    color #606e79
  #main-content
    width 100%
    height 100%
    margin-top 20px
    min-height 80vh
    #content-header
      padding 1rem 0.5rem
    #table
      .table-row
        height 50px
        display flex
        justify-content center
        align-items center
        background #eceeef
        color #374047
        margin-bottom 2px
        cursor pointer
        padding 0 0.2rem
        .date
          width 20%
        .name
          width 45%
          line-height 50px
          text-decoration underline
          cursor pointer
        .date,.name
          line-height 3.3vw
          font-size 3.3vw
        .name:hover
          color #0664a9
        .approval
          width 15%
          .approval-button
            background #006fbe
          .approval-button:hover
            background #004170
            color #eceeef
        .rejection
          width 15%
          .rejection-button
            background #f8dadc
            color #7f8b93
          .rejection-button:hover
            background #98a1a8
            color #eceeef         
        .approval-button,.rejection-button
          box-shadow 1px 1px 2px #000
          text-align center
          color #eceeef
          border-radius 5px
          width 90%
          cursor pointer
          height 1.5rem
          margin-top 0.2rem

// 大デバイス（デスクトップ, 1170px 以上）
@media (min-width 1170px)
  .wrapper
    #main-content
      width 60%
      margin 1rem auto
      #content-header
        color #000000
        padding 2rem 0
      #table
        .table-row
          .date,.name
            line-height 1.2rem
            font-size 1.2rem

</style>